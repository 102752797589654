import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const buttonsDefault = {
    padding: 0,
    margin: '5px 20px',
    borderRadius: 3,
    width: 240,
    height: 45,
    backgroundColor: '#272652',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'normal',
    display: 'block',
    color: 'white',
    '&:hover': {
      backgroundColor: '#272652',
    },
  };


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 12,
  },
  googleButton: {
    ...buttonsDefault,
    backgroundColor: '#d64937',
    '&:hover': {
      backgroundColor: '#d64937',
    },
  },
  facebookButton: {
    ...buttonsDefault,
    backgroundColor: '#4867aa',
    '&:hover': {
      backgroundColor: '#4867aa',
    },
  },
  emailButton: {
    ...buttonsDefault,
    backgroundColor: '#9fd313',
    '&:hover': {
      backgroundColor: '#9fd313',
    },
  },
}));

function RootSignIn(props) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Button variant="contained"
        className={classes.googleButton}
        disableElevation
        >
        Google
      </Button>
      <Button variant="contained"
        className={classes.facebookButton}
        disableElevation
        >
        Facebook
      </Button>
      <Button variant="contained"
        className={classes.emailButton}
        disableElevation
        onClick={props.onEmailClick}
        >
        Войти через эл. почту
      </Button>
    </form>
  )
}
export default RootSignIn;
