import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import unauthorizedStub from './unauthorized-stub.svg';

const useStyles = makeStyles({
  root: props => ({
    height: props.height,
  }),
  avatar: {
    width: 60,
    height: 60,
    margin: '25px 35px 25px 35px'
  },
});

function Profile(props) {
 const height = (props.signedIn === true?150:110)
  const classes = useStyles({height: height});

  return (
    <div className={classes.root} onClick={props.onClick}>
      <Avatar alt="Remy Sharp" src={unauthorizedStub} className={classes.avatar} />
    </div>
  )
}
export default Profile;
