import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 12,
  },
  backButton: {
    padding: '8px 20px',
    textTransform: 'none',
    fontSize: 13,
    fontWeight: 'normal',
    color: '#392d81',
    '&:hover': {
      background: 'unset',
    },
  },
  textField: {
    color:'red',
  },
  inputFormControl: {
    width:242,
    margin: '12px 19px',
  },
  inputLabel: {
    color:'black',
    fontSize: 14,
    '&.Mui-focused': {
      color:'black',
    }
    // c0cbd0
  },
  inputField: {
    color:'black',
    fontSize: 14,
    background: 'white',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#c0cbd0',
      borderRadius: 0,
    },
    '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#90a6b0',
      borderWidth: 1,
    },
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#90a6b0',
      borderWidth: 1,
    },
  },
  fogotButton: {
    padding: '2px 20px',
    textTransform: 'none',
    fontSize: 13,
    fontWeight: 'normal',
    color: '#392d81',
    display: 'block',
    '&:hover': {
      background: 'unset',
    },
  },
  singInButton: {
    padding: '4px 14px',
    margin: '10px 20px',
    backgroundColor: '#272652',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'bold',
    display: 'block',
    '&:hover': {
      backgroundColor: '#272652',
    },
  },
}));

function SignInForm(props) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Button className={classes.backButton} onClick={props.onBackClick}>Назад</Button>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.inputFormControl}
        >
        <InputLabel className={classes.inputLabel} htmlFor="sign-in-em-input">Электронная почта</InputLabel>
        <OutlinedInput
          id="sign-in-em-input"
          label="Электронная почта"
          className={classes.inputField}
          />
      </FormControl>

      <FormControl
        variant="outlined"
        size="small"
        className={classes.inputFormControl}
        >
        <InputLabel className={classes.inputLabel} htmlFor="sign-in-pass-input">Пароль</InputLabel>
        <OutlinedInput
          type="password"
          id="sign-in-pass-input"
          label="Пароль"
          className={classes.inputField}
          />
      </FormControl>
      <Button className={classes.fogotButton} onClick={props.onFogotClick}>Забыли пароль?</Button>
      <Button variant="contained"
        className={classes.singInButton}
        color="primary"
        disableElevation
        onClick={() => props.onSingIn('swift', 'swift')}
        >
        Войти
      </Button>
    </form>
  )
}
export default SignInForm;

function SignUpForm(props) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <FormControl
        variant="outlined"
        size="small"
        className={classes.inputFormControl}
        >
        <InputLabel className={classes.inputLabel} htmlFor="sign-up-em-input">Электронная почта</InputLabel>
        <OutlinedInput
          id="sign-up-em-input"
          label="Электронная почта"
          className={classes.inputField}
          />
      </FormControl>

      <FormControl
        variant="outlined"
        size="small"
        className={classes.inputFormControl}
        >
        <InputLabel className={classes.inputLabel} htmlFor="sign-up-pass-input">Пароль</InputLabel>
        <OutlinedInput
        type="password"
          id="sign-up-pass-input"
          label="Пароль"
          className={classes.inputField}
          />
      </FormControl>

      <FormControl
        variant="outlined"
        size="small"
        className={classes.inputFormControl}
        >
        <InputLabel className={classes.inputLabel} htmlFor="sign-up-pass-repeat-input">Пароль повторно</InputLabel>
        <OutlinedInput
        type="password"
          id="sign-up-pass-repeat-input"
          label="Пароль повторно"
          className={classes.inputField}
          />
      </FormControl>

      <Button variant="contained"
        className={classes.singInButton}
        color="primary"
        disableElevation
        >
        Зарегистрироваться
      </Button>
    </form>
  )
}

function RestorePasswordForm(props) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Button className={classes.backButton} onClick={props.onBackClick}>Назад</Button>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.inputFormControl}
        >
        <InputLabel className={classes.inputLabel} htmlFor="fogot-em-input">Электронная почта</InputLabel>
        <OutlinedInput
          id="fogot-em-input"
          label="Электронная почта"
          className={classes.inputField}
          />
      </FormControl>

      <Button variant="contained"
        className={classes.singInButton}
        color="primary"
        disableElevation
        onClick={() => {
          if (props.onReset !== undefined)
            props.onReset('swift')
        }}
        >
        Отправить письмо
      </Button>
    </form>
  )
}

export { SignInForm, SignUpForm, RestorePasswordForm };
