import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import logo from './logo.png';
import addWork from './add-work.svg';
import Profile from './Profile/Profile';


const drawerWidth = 130;
const newWorkLabel = 'Новая работа'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 80,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: 'white',
    zIndex: 1400,
    boxShadow: '0px 0px 20px 0px #d3d6d9',
  },
  newWork: {
    height: 110,
    textAlign: 'center',
  },
  newWorkIcon: {
    width: 40,
    height: 40,
    margin: '16px 44px 8px 44px'
  },
  newWorkText: {
    fontSize: 11,
    fontFamily: "PT Sans",
    color: 'rgb(57, 45, 129)',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'default',
  }
}));

function Menu(props) {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left">
      <img src={logo} alt="Logo" className={classes.logo}/>
      <Profile
        signedIn={false}
        onClick={props.profileClick}
        />
      <Divider />
      <div className={classes.newWork}>
        <img src={addWork} alt="newWork" className={classes.newWorkIcon}/>
        <span className={classes.newWorkText}>{newWorkLabel}</span>
      </div>
      <Divider />
    </Drawer>
  )
}
export default Menu;
