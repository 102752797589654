import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SignInForm, SignUpForm, RestorePasswordForm } from './SignForms/SignForms'
import RootSignIn from './RootSignIn/RootSignIn'


const useStyles = makeStyles((theme) => ({
  signInDrawer: {
    width: 250,
    flexShrink: 0,
    marginLeft: 130,
  },
  signInDrawerPaper: {
    width: 280,
    background: '#fbfcfc',
    boxShadow: '0px 0px 20px 0px #d3d6d9',
    marginLeft: 130,
  },
  tabHeader: {
    height: 80,
    boxShadow: '0px 0px 20px 0px #d3d6d9',
    padding: '0 18px',
  },
  tabFlex: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
  },
  tabIndicator: {
    height: 31,
    backgroundColor: '#9fd313',
  },
  tabRoot: {
    fontSize: 14,
    minWidth:0,
    minHeight:0,
    margin: '0 6px',
    padding: '3px 12px',
    color: '#392d81',
  },
  tabWrapper: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
  tabSelected: {
    color: 'white',
  }
}));

function SignIn(props) {
  const classes = useStyles();

  const [tabValue, setTabValue] = React.useState(0);
  const [sigInSwipeValue, setSigInSwipeValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setSigInSwipeValue(0);
  };

  return (
    <Drawer
      className={classes.signInDrawer}
      open={props.isOpen}
      classes={{
        paper: classes.signInDrawerPaper,
      }}
      anchor="left"
      onClose={props.onClose}
      >
      <div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          className={classes.tabHeader}
          classes={{
            flexContainer: classes.tabFlex,
            indicator: classes.tabIndicator,
          }}
          >
          <Tab
            label="Вход"
            className={classes.tabRoot}
            classes={{
              wrapper: classes.tabWrapper,
              selected: classes.tabSelected,
            }}
            />
          <Tab label="Регистрация"
            className={classes.tabRoot}
            classes={{
              wrapper: classes.tabWrapper,
              selected: classes.tabSelected,
            }}
          />
        </Tabs>
      </div>
      <SwipeableViews
        axis='x'
        index={tabValue}
        disabled={true}
        >
        <SwipeableViews
          axis='x'
          index={sigInSwipeValue}
          disabled={true}
          >
          <RootSignIn
            onEmailClick={() => setSigInSwipeValue(1)}
          />
          <SignInForm
            onSingIn={() => setSigInSwipeValue(0)}
            onBackClick={() => setSigInSwipeValue(0)}
            onFogotClick={() => setSigInSwipeValue(2)}
            />
          <RestorePasswordForm
            onBackClick={() => setSigInSwipeValue(1)}
          />
        </SwipeableViews>
        <SignUpForm />
      </SwipeableViews>
    </Drawer>
  )
}
export default SignIn;
